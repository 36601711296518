import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import type { SoviOnboardingStep } from '@/types/onboarding';
import { tourRepository } from '@/repositories';
import { getOnboardingSteps } from '@/utils/onboarding';

export const useOnboardingStore = defineStore('onboarding', () => {
  const tour = ref<SoviOnboardingStep[]>([]);

  const seenTours = ref<string[]>([]);

  const tourName = ref('');

  const isTourActive = ref(false);

  const currentTourStepIndex = ref(0);

  const showWelcomeDialog = ref(false);

  const showFirstContractDialog = ref(false);

  const showCookieConsent = ref(false);

  const startOnboardingTour = (
    locale: string,
    name: string,
    manualStart = false,
  ) => {
    if (seenTours.value.includes(name) && !manualStart) return;

    document.querySelector('.v-application')?.scrollTo(window.scrollX, 0);

    currentTourStepIndex.value = 0;
    isTourActive.value = true;
    tour.value = getOnboardingSteps(locale as 'fi' | 'en', name);
    tourName.value = name;
  };

  const endOnboardingTour = async () => {
    currentTourStepIndex.value = 0;
    isTourActive.value = false;

    if (!seenTours.value.includes(tourName.value)) {
      await markTourAsSeen();
    }
  };

  const currentTourStep = computed(() =>
    isTourActive.value ? tour.value?.[currentTourStepIndex.value] : undefined,
  );

  const isLastStep = computed(
    () => currentTourStepIndex.value === tour.value.length - 1,
  );

  const isStepActive = computed(
    () => (name: string, step: string) =>
      name === tourName.value &&
      `[data-step="${step}"]` === currentTourStep.value?.target,
  );

  const markTourAsSeen = async (name?: string) => {
    try {
      const { success } = await tourRepository.markAsSeen(
        name || tourName.value,
      );

      if (!success) throw new Error('Error marking the tour as seen');

      seenTours.value.push(name || tourName.value);

      tour.value = [];
      tourName.value = '';
    } catch (error) {
      console.error(error);
    }
  };

  return {
    currentTourStep,
    currentTourStepIndex,
    isLastStep,
    isTourActive,
    tour,
    isStepActive,
    seenTours,
    showWelcomeDialog,
    showFirstContractDialog,
    showCookieConsent,
    endOnboardingTour,
    markTourAsSeen,
    startOnboardingTour,
  };
});
