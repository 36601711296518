<template>
  <sovi-dialog :show="display" :title="t('networkIssues')">
    <p>
      {{ t('waitOrReload') }}
    </p>

    <template #actions>
      <v-spacer />

      <v-btn variant="elevated" rounded @click="reloadPage()">
        {{ t('reload') }}
      </v-btn>
    </template>
  </sovi-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  display: boolean;
}>();

const reloadPage = () => {
  window.location.reload();
};
</script>

<!-- eslint-disable -->
<i18n lang="json">
{
  "fi": {
    "networkIssues": "Tarkista internet-yhteytesi",
    "waitOrReload": "Yhteydessäsi havaittiin ongelma. Varmista internet-yhteys ja lataa sivu uudelleen jatkaaksesi.",
    "reload": "Lataa uudelleen"
  },
  "en": {
    "networkIssues": "Check your network connection",
    "waitOrReload": "A network issue has been detected. Please check your network connection and reload the page to continue.",
    "reload": "Reload page"
  }
}
</i18n>
<!-- eslint-enable -->
