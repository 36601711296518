import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import { usePdfjsPlugin } from '@/plugins/pdf';
import { socketIoPlugin } from '@/plugins/socket.io';
import { createSentryInstance } from '@/plugins/sentry';
import VueGtag from 'vue-gtag';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';

import '@/assets/base.css';
import '@fontsource-variable/quicksand';

const app = createApp(App);

usePdfjsPlugin(app);

createSentryInstance(app);

app.use(createPinia());

app.use(vuetify);

app.use(i18n);

app.use(router);

app.use(autoAnimatePlugin);

const allowTracking = localStorage.getItem('allowTracking');

console.log(allowTracking === 'true');

app.use(VueGtag, {
  bootstrap: allowTracking === 'true',
  config: {
    id: 'AW-16774635677',
  },
});

app.use(socketIoPlugin, {
  connection: import.meta.env.VITE_WS_URL || 'http://localhost:3000',
  options: {
    transports: ['websocket'],
    extraHeaders: {
      'Access-Control-Allow-Credentials': true,
    },
  },
});

app.mount('#app');
