<template>
  <footer class="p-2" :class="{ 'bg-tertiary-200': !transparent }">
    <div
      class="rounded-2xl"
      :class="{
        'dark:text-white': transparent,
        'bg-dark-800 text-white': !transparent,
      }"
    >
      <div class="mx-auto flex max-w-screen-3xl px-2 pt-1 sm:px-4 md:px-8">
        <v-spacer />

        <v-fab-transition>
          <v-icon-btn
            v-if="isHelpVisible && route.meta.tour"
            variant="elevated"
            size="large"
            icon="mdi-help"
            @click="startOnboardingTour(locale, route.meta.tour, true)"
          />
        </v-fab-transition>
      </div>

      <div
        class="mx-auto my-0 flex max-w-screen-3xl items-start p-4 sm:px-4 md:px-8"
      >
        <sovi-logo
          :dark="!transparent"
          :size="48"
          :tertiary="!theme.current.value.dark"
        />

        <v-spacer />

        <div class="text-right text-sm">
          <p class="font-semibold">Sovi Agreements</p>

          <p class="text-xs">{{ t('businessID') }}: 2402115-6</p>

          <p class="my-1 text-xs">
            <a
              target="_blank"
              class="decoration-tertiary font-semibold text-tertiary-600 underline-offset-4 hover:underline"
              rel="noopener noreferrer"
              href="/pdf/Sovi_ehdot.pdf"
              @click.stop
            >
              {{ t('terms') }}
            </a>
          </p>

          <p class="my-1 text-xs">
            <a
              target="_blank"
              class="decoration-tertiary font-semibold text-tertiary-600 underline-offset-4 hover:underline"
              rel="noopener noreferrer"
              href="/pdf/Sovi_tietosuojaseloste.pdf"
              @click.stop
            >
              {{ t('dataProtectionStatement') }}
            </a>
          </p>

          <p class="my-1 text-xs">
            <a
              target="_blank"
              class="decoration-tertiary font-semibold text-tertiary-600 underline-offset-4 hover:underline"
              rel="noopener noreferrer"
              href="/pdf/Sovi_toimitus_ja_maksuehdot.pdf"
              @click.stop
            >
              {{ t('paymentTerms') }}
            </a>
          </p>

          <p
            class="decoration-tertiary my-1 cursor-pointer text-xs font-semibold text-tertiary-600 underline-offset-4 hover:underline"
            @click="showCookieConsent = true"
            @keyup.enter="showCookieConsent = true"
          >
            {{ t('cookies') }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import { useOnboardingStore } from '@/stores/onboarding';
import { useRoute } from 'vue-router';
import { useContract } from '@/composables/useContract';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { t } = useI18n();

const { locale } = useI18n({ useScope: 'global' });

const { isContractSent } = useContract();

const { startOnboardingTour } = useOnboardingStore();

const { showCookieConsent } = storeToRefs(useOnboardingStore());

const route = useRoute();

const theme = useTheme();

const isHelpVisible = computed(() => {
  if (route.name === 'Contract' && isContractSent.value) return false;

  return true;
});

defineProps<{ transparent?: boolean }>();
</script>

<i18n lang="json">
{
  "fi": {
    "businessID": "Y-tunnus",
    "terms": "Käyttöehdot",
    "dataProtectionStatement": "Tietosuojaseloste",
    "paymentTerms": "Toimitus- ja maksuehdot",
    "cookies": "Evästeet"
  },
  "en": {
    "businessID": "Business ID",
    "terms": "Terms of use",
    "dataProtectionStatement": "Data protection statement",
    "paymentTerms": "Terms of payment and delivery",
    "cookies": "Cookies"
  }
}
</i18n>
